import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import logo from './../img/mango (1).png';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">

          <NavLink exact="true" to="/" className="logo-container">
            <img className="nav-logo" src={logo} alt="Logo" />
            <span className="nav-logo-text">Mango Leaf Disease</span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/"
                activeclassname="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/about"
                activeclassname="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/predict"
                activeclassname="active"
                className="nav-links"
                onClick={handleClick}
              >
                Predict
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
          {click ? <CloseIcon /> : <MenuIcon />}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
