import './App.css';
import NavBar from "./components/NavBar";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Predict } from "./components/Pages/Predict";
import { Contact } from "./components/Pages/Contact";
function App() {
  return (
    <>
    <Router>
      <NavBar />

      <div className="pages">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/predict" element={<Predict />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
</>

  );
}

export default App;
