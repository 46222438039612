import React, { useState, useEffect } from "react";
import { Grid, Card, CardMedia, CardActionArea, CardContent, CircularProgress, Typography, Button } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import ClearIcon from '@mui/icons-material/Clear'; 
import "./Home.css"; 
const axios = require("axios").default;
  
export const Home = () => {
  let confidence = 0; 
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [data, setData] = useState();
  const [image, setImage] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (!preview) {
      return;
    }
    setIsloading(true);
    sendFile();
    // eslint-disable-next-line
  }, [preview]);

  
  const sendFile = async () => {
    if (image) {
      let formData = new FormData();
      formData.append("file", selectedFile);
      let res = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL,
        data: formData,
      });

      if (res.status === 200) {
        setData(res.data);
      }
      setIsloading(false);
    }
  }
  const clearData = () => {
    setData(null);
    setImage(false);
    setSelectedFile(null);
    setPreview(null);
  };
  const onSelectFile = (files) => {
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      setImage(false);
      setData(undefined);
      return;
    }
    setSelectedFile(files[0]);
    setData(undefined);
    setImage(true);
  };
  if (data) {
    confidence = (parseFloat(data.confidence) * 100).toFixed(2);
  }
  return (
    <div className="mainContainer">
      <Grid item xs={8} className="contentGrid">
        <Card className={`imageCard ${!image ? 'imageCardEmpty' : ''}`}>
          {image && <CardActionArea>
            <CardMedia
              className='media'
              image={preview}
              component="img"
              title="sample image"
            />
          </CardActionArea>
          }
          {!image && <CardContent >
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={"Drag and drop an image of a mango leaf to process.."}
              maxFileSize={8000000}
              onChange={onSelectFile}
              maxFiles={1}
            />
          </CardContent>}
          {data && <CardContent className="grid-container" > 
              <div className="item resultHeading" >Label:</div>
              <div  className="item resultHeading">Confidence:</div>
              <div className="item resultBody">{data.class}</div>
              <div className="item resultBody">{confidence}%</div> 
          </CardContent>}
          {isLoading && <div > 
            <CircularProgress color="success" style={{padding:'5px'}}/>
            <Typography variant="h6" nowrap='true'> Processing</Typography>
          </div>}
        </Card>
      </Grid>
      {data &&
        <div className='buttonGrid' >
          <Button variant="contained" className='clearButton' fullWidth={true} size="large" onClick={clearData} startIcon={<ClearIcon fontSize="large" />}>Clear</Button>
        </div>}
    </div>
  );
};
